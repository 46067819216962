<template>
  <div class="meeting-list-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item>会议列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入会议名称" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addMeeting">添加会议</el-button>
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="title" label="会议名称"></el-table-column>
        <el-table-column prop="status" label="会议状态">
          <template slot-scope="scope">
            <el-tag type="warning" effect="dark" v-if="nowTime < scope.row.start_time && scope.row.start_time !== 0">未开始</el-tag>
            <el-tag type="info" effect="dark" v-else-if="nowTime > scope.row.end_time && scope.row.end_time !== 0">已结束</el-tag>
            <el-tag type="success" effect="dark" v-else>进行中</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="small" v-clipboard:copy="'https://' + domain + '/#/home/' + scope.row.m_id" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</el-button>
            <el-button size="small" type="success" @click="contentItem(scope.row.m_id)">内容设置</el-button>
            <el-button size="small" type="success" @click="editItem(scope.row.m_id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingList',
  data () {
    return {
      nowTime: Number(String((new Date()).getTime()).slice(0, 10)),
      query: {
        title: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      message: '复制链接',
      dataList: [],
      domain: ''
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取已添加全景项目列表
    async getDataList () {
      const { data: res } = await this.$http.get('shell-meeting-list', { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list.data
        this.query.total = Number(res.data.list.total)
        this.query.currentPage = Number(res.data.list.current_page)
        this.query.pageSize = Number(res.data.pageSize)
        this.domain = res.data.domain
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加会议
    addMeeting () {
      this.$router.push('/admin/apps/shell/add')
    },
    // 编辑会议
    editItem (mId) {
      this.$router.push('/admin/apps/shell/edit/' + mId)
    },
    // 删除会议
    async delItem (mId) {
      const { data: res } = await this.$http.delete('/shell-meeting/' + mId)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 设置会议内容
    contentItem (mId) {
      this.$router.push('/admin/apps/shell/content/index/' + mId)
    },
    // 复制链接
    onCopy (e) {
      this.$message.success('复制成功')
    },
    onError (e) {
      this.$message.error('复制失败')
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
.el-radio-group .el-radio{
  margin-bottom: 10px;
}
</style>
